.pageHeader {
  color: #252947;	
  font-family: 'Poppins', sans-serif;	
  font-size: 22px;	
  font-weight: bold;	
  line-height: 50px;	
  text-align: center;
  padding-top: 12vh;
  margin: auto;
}

body {
  box-sizing: border-box
}

.datesHeader {
  color: #252947;	
  font-family: 'Poppins', sans-serif;	
  font-size: 22px;	
  font-weight: bold;	
  line-height: 50px;	
  text-align: center;
  margin-top: 20px;
  margin: auto;
}

.link-button {
  background:none;
  color:inherit;
  border:none;
  padding:0;
  font: inherit;
  outline:0;
  border-bottom:1px solid #444; 
  cursor: pointer;
}

.preferencesHeader {
  margin-top: 0;
  margin-bottom: 0;
}

.profileInput {
  height: 5vh;
}

.userForms {
  width: 100%;
  margin: auto;
  max-width: 570px;
  padding: 40px;
  padding-bottom: 0;
}

.infoDiv {
  width: 100%;
  margin: auto;
  max-width: 570px;
  padding: 40px;
  padding-bottom: 0;
}

@media (min-width: 800.1px) {
  .userInfoLabel {
    text-align: left;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .userInfoLabel {
    text-align: center;
    margin: 0;
  }
}

.userInput {
  width: 100%;
}

.pageCaption {
  color: #252947;
  width: 35.625rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 570px;
}

.datesCaption {
  color: #252947;
  width: 100%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
}

input[type="text"]{
  font-family: Poppins;
  font-weight: normal;
  font-size: 17px;
  color: "#252947";
  padding-left: 12px;
}

input[type="number"]{
  font-family: Poppins;
  font-weight: normal;
  font-size: 17px;
  color: "#252947";
  padding-left: 12px;
}

.inputDiv {
  height: 91px;
  margin-bottom: 30px;
}

.custom-select {
  width: 70px;
  height: 50px;
}

.infoInput {
  height: 50px;
  background-color: #f4f4f6;
  outline: none;
  border: transparent;
  width: 100%;
}

/* For font-color of placeholders */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CBCCD4;
  opacity: 1; /* Firefox */
}

#budgetSubHeader {
  margin-top: 25px;
  margin-bottom: 2vh;
}

/* This line makes the "Register" and "Login" buttons look like links */
.link-button {
  background:none;
  color:inherit;
  border:none;
  padding:0;
  font: inherit;
  outline:0;
  border-bottom:1px solid #c8c9d1; 
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #c8c9d1;
}

option:disabled {
 color: #7EB8C4
}