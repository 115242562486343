.pageHeader {
  color: #252947;	
  font-family: 'Poppins', sans-serif;	
  font-size: 22px;	
  font-weight: bold;	
  line-height: 50px;	
  text-align: center;
  padding-top: 12vh;
  margin: auto;
}

body {
  box-sizing: border-box
}

.datesHeader {
  color: #252947;	
  font-family: 'Poppins', sans-serif;	
  font-size: 22px;	
  font-weight: bold;	
  line-height: 50px;	
  text-align: center;
  margin-top: 20px;
  margin: auto;
}

.link-button {
  background:none;
  color:inherit;
  border:none;
  padding:0;
  font: inherit;
  outline:0;
  border-bottom:1px solid #444; 
  cursor: pointer;
}

.preferencesHeader {
  margin-top: 0;
  margin-bottom: 0;
}

.profileInput {
  height: 5vh;
}

.userForms {
  width: 100%;
  margin: auto;
  max-width: 570px;
  padding: 40px;
  padding-bottom: 0;
}

.infoDiv {
  width: 100%;
  margin: auto;
  max-width: 570px;
  padding: 40px;
  padding-bottom: 0;
}

@media (min-width: 800.1px) {
  .userInfoLabel {
    text-align: left;
    margin: 0;
  }
}

@media (max-width: 800px) {
  .userInfoLabel {
    text-align: center;
    margin: 0;
  }
}

.userInput {
  width: 100%;
}

.pageCaption {
  color: #252947;
  width: 35.625rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 570px;
}

.datesCaption {
  color: #252947;
  width: 100%;
  font-family: Poppins;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
}

input[type="text"]{
  font-family: Poppins;
  font-weight: normal;
  font-size: 17px;
  color: "#252947";
  padding-left: 12px;
}

input[type="number"]{
  font-family: Poppins;
  font-weight: normal;
  font-size: 17px;
  color: "#252947";
  padding-left: 12px;
}

.inputDiv {
  height: 91px;
  margin-bottom: 30px;
}

.custom-select {
  width: 70px;
  height: 50px;
}

.infoInput {
  height: 50px;
  background-color: #f4f4f6;
  outline: none;
  border: transparent;
  width: 100%;
}

/* For font-color of placeholders */

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CBCCD4;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CBCCD4;
  opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CBCCD4;
  opacity: 1; /* Firefox */
}

#budgetSubHeader {
  margin-top: 25px;
  margin-bottom: 2vh;
}

/* This line makes the "Register" and "Login" buttons look like links */
.link-button {
  background:none;
  color:inherit;
  border:none;
  padding:0;
  font: inherit;
  outline:0;
  border-bottom:1px solid #c8c9d1; 
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #c8c9d1;
}

option:disabled {
 color: #7EB8C4
}
.logo {
    margin-right: 12vw;    
    display: block;
}
.navbar {
    display: -webkit-flex;
    display: flex;
    margin: auto;
}
.menuIcon {
    position: absolute;
    left: 2vmin;
}

.nav-right {
    float: right;
  }

.nav-link {
float: right;
color: white;
background-color: inherit;
text-align: center;
text-decoration: none;
font-size: 15px;
font-family: "Poppins";
    
/* these last two make the logout button in
the nav bar look like another link */
border:none;
cursor: pointer;
outline:0;
}
body {
    text-align: center;
}

.registerLabel {
    margin: 0;
    padding-bottom: 0;
}

.userRegister {
    width: 40vmin;
    height: 3.5vh;
}
.addTravelerButtons {
    width: 30vh;
    margin: auto;

}

.alignleft {
	float: left;
}
#searchIcon {
    width: 25px;
    height: 25px;
    /* position: absolute;
    top: 385px;
    right: 28vw; */
    float: right;
    margin-top: 10px;
}

.addUserInputDiv {
    display: "inline-flex",
}
ul {
    list-style-type: none;
  }
  



.travelerIcon {
    font-size: 14px;
    margin-right: 12px;
    vertical-align: middle;
    color: #f16b52;
    display: inline-block;
}

.iconContainer {
    max-width: 570px;
    height: 50px;
    line-height: 40px;
    margin: auto;
    border-bottom: solid 0.5px rgba(37, 41, 71, 0.25);
}

@media (min-width: 800.1px) {
    .buttonContainer {
        width: 490px;
        height: 50px;
        line-height: 40px;
        margin: auto;
    }
}
@media (max-width: 800px) {
    .buttonContainer {
        width: 280px;
        height: 50px;
        line-height: 40px;
        margin: auto;
    }
}

.addIconContainer {
    position: relative;
    height: 3vmin;
    margin-right: 14vmin;
    width: 70vmin;
}

#userName {
    height: 26px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f16b52;
    display: inline-block;
}

.travelers {
    max-width: 570px;
    width: 100%;
    margin: auto;
}

.travelersLine {
    width: 570px;
    border: solid 0.5px rgba(37, 41, 71, 0.25);
    color: rgba(37, 41, 71, 0.25)
}

.checkMark {
    float: right;
    vertical-align: middle;
    height: 23px;
}

.addTravelerButton {
    color: #CBCCD4;
}

.profileSelect {
  width: 100%;
}

profileInput {
  width: 100%;
  background-color: #f4f4f6;
}

#yearSelect {
  width: 80px;
}

.filledInput {
  background-color: #f4f4f6;
}

.genderDiv {
  padding-top: 2.47vh;
}

.nextButton {
  padding-bottom: 150px;
}

#nextIconArrow {
  height: 2px;
  width: 2px;
}

#travelerInfoForm {
  height: 575px;
}

.disabledSelect {
  color: #cbccd4;
}

captionContext {
  color: #cbccd4;
  font-size: 14px;
}
.addressDiv {
    margin-top: 12px;
}

#contactInfoForm {
    height: 411px;
}

input[type="text"]:disabled {
    color: #CBCCD4
  }

  @media (min-width: 800.1px) {
    #cityInput {
        width: 200px;
        margin-right: 20px;
    }
    #stateSelect {
        margin-right: 20px;
        width: 85px;
        margin-top: 1px;
    }
    #zipCodeInput {
        width: 165px;
    }
}

@media (max-width: 800px) {
    #cityInput {
        width: 100%;
        margin-right: 20px;
    }
    #stateSelect {
        margin-right: 20px;
        width: 85px;
        margin-top: 1px;
    }
    #zipCodeInput {
        width: 225px;
    }
}
.confirmationButtonStyle {
    font-Weight: bold;
    display: inline-block;
    -webkit-align-items: center;
            align-items: center;
    margin: 0;
    border-radius: 25px;
    height: 50px;
    width: 168px;
    font-size: 16px;
    line-height: 16px;;
    text-align: center;
}

.rightConfirmationColumn {
    float: right;
}

.leftConfirmationColumn {
    width: 25%;
    float: left;
    margin-left: 30%;
}

.confirmationColumn {
    margin: 0;
}

.confirmationButtons {
    margin-top: 8%;
    margin-bottom: 10vh;
}

.confirmationInfoLabel {
    text-align: left;
    margin: 0;
    color: #000035;
    font-size: 18px;
}

.confirmationText {
    margin: 0;
    font-size: 14px;
    margin-bottom: 8px;
}

.welcomeText {
    color: #252947;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    margin: auto;
}

.welcomeHeader {
    color: #252947;	
    font-family: 'Poppins', sans-serif;	
    font-size: 22px;	
    font-weight: bold;	
    line-height: 50px;	
    text-align: center;
    margin: auto;
}



.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  width: 159px;
  height: 46px;
  display: inline-block;
  background-color: #ffffff;
  color: #c8c9d1;
  font-family: Poppins;
  font-size: 16px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}

.radio-toolbar label:hover {
  background-color: #f16b52;
  color: white;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #f16b52;
  border-color: #f16b52;
  color: white;
}

.budgetPreferenceRange {
    line-height: 1.33;
}

.budgetPreferenceRange {
    font-size: 12px;
    display: block;
    height: 12px;
}

.budgetHeader {
    color: #252947;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding-top: 12vh;
    margin: auto;
}

.preferenceLabel {
  margin-bottom: 0;
  margin-top: 8px;
}
.accomodationCheckBoxes {
    margin: auto;
}

#hotelLabel {
    padding-left: 110px;
}

.vacationRentalBox input[type="checkbox"]{
    display: none;
}

.vacationRentalBox input[type="checkbox"]+label{
    background: url('https://cdn.liverez.com/5/10733/5/156/categories/3047.jpg') no-repeat;
    width:20px;
    height: 20px;
}

.vacationRentalBox input[type="checkbox"]:checked + label{
    border: 4px solid blue;
}




@media (min-width: 800.1px) {
    #confirmDatesRadioGroup {
        background-color: white;
        display: block;
        width: 150px;
        margin: auto;
    }
}

@media (max-width: 800px) {
    #confirmDatesRadioGroup {
        background-color: white;
        display: block;
        margin: auto;
        width: 40%;
    }
}

.dateOptions {
    font-family: Poppins;
    font-size: 18px;
    /* font-weight: normal; */
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c8c9d1;
}

#dateform {
    margin-top: 25px;
}

#dateOptionOne {
    margin-top: 50px;
}
