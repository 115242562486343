.radio-toolbar input[type="radio"] {
  display: none;
}

.radio-toolbar label {
  width: 159px;
  height: 46px;
  display: inline-block;
  background-color: #ffffff;
  color: #c8c9d1;
  font-family: Poppins;
  font-size: 16px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
}

.radio-toolbar label:hover {
  background-color: #f16b52;
  color: white;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #f16b52;
  border-color: #f16b52;
  color: white;
}

.budgetPreferenceRange {
    line-height: 1.33;
}

.budgetPreferenceRange {
    font-size: 12px;
    display: block;
    height: 12px;
}

.budgetHeader {
    color: #252947;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding-top: 12vh;
    margin: auto;
}

.preferenceLabel {
  margin-bottom: 0;
  margin-top: 8px;
}