#searchIcon {
    width: 25px;
    height: 25px;
    /* position: absolute;
    top: 385px;
    right: 28vw; */
    float: right;
    margin-top: 10px;
}

.addUserInputDiv {
    display: "inline-flex",
}