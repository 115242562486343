body {
    text-align: center;
}

.registerLabel {
    margin: 0;
    padding-bottom: 0;
}

.userRegister {
    width: 40vmin;
    height: 3.5vh;
}