.profileSelect {
  width: 100%;
}

profileInput {
  width: 100%;
  background-color: #f4f4f6;
}

#yearSelect {
  width: 80px;
}

.filledInput {
  background-color: #f4f4f6;
}

.genderDiv {
  padding-top: 2.47vh;
}

.nextButton {
  padding-bottom: 150px;
}

#nextIconArrow {
  height: 2px;
  width: 2px;
}

#travelerInfoForm {
  height: 575px;
}

.disabledSelect {
  color: #cbccd4;
}

captionContext {
  color: #cbccd4;
  font-size: 14px;
}