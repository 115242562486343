.dateOptions {
    font-family: Poppins;
    font-size: 18px;
    /* font-weight: normal; */
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c8c9d1;
}

#dateform {
    margin-top: 25px;
}

#dateOptionOne {
    margin-top: 50px;
}