.travelerIcon {
    font-size: 14px;
    margin-right: 12px;
    vertical-align: middle;
    color: #f16b52;
    display: inline-block;
}

.iconContainer {
    max-width: 570px;
    height: 50px;
    line-height: 40px;
    margin: auto;
    border-bottom: solid 0.5px rgba(37, 41, 71, 0.25);
}

@media (min-width: 800.1px) {
    .buttonContainer {
        width: 490px;
        height: 50px;
        line-height: 40px;
        margin: auto;
    }
}
@media (max-width: 800px) {
    .buttonContainer {
        width: 280px;
        height: 50px;
        line-height: 40px;
        margin: auto;
    }
}

.addIconContainer {
    position: relative;
    height: 3vmin;
    margin-right: 14vmin;
    width: 70vmin;
}

#userName {
    height: 26px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f16b52;
    display: inline-block;
}

.travelers {
    max-width: 570px;
    width: 100%;
    margin: auto;
}

.travelersLine {
    width: 570px;
    border: solid 0.5px rgba(37, 41, 71, 0.25);
    color: rgba(37, 41, 71, 0.25)
}

.checkMark {
    float: right;
    vertical-align: middle;
    height: 23px;
}

.addTravelerButton {
    color: #CBCCD4;
}