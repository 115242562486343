.accomodationCheckBoxes {
    margin: auto;
}

#hotelLabel {
    padding-left: 110px;
}

.vacationRentalBox input[type="checkbox"]{
    display: none;
}

.vacationRentalBox input[type="checkbox"]+label{
    background: url('https://cdn.liverez.com/5/10733/5/156/categories/3047.jpg') no-repeat;
    width:20px;
    height: 20px;
}

.vacationRentalBox input[type="checkbox"]:checked + label{
    border: 4px solid blue;
}
