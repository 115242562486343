.welcomeText {
    color: #252947;
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    margin: auto;
}

.welcomeHeader {
    color: #252947;	
    font-family: 'Poppins', sans-serif;	
    font-size: 22px;	
    font-weight: bold;	
    line-height: 50px;	
    text-align: center;
    margin: auto;
}
