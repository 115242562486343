.logo {
    margin-right: 12vw;    
    display: block;
}
.navbar {
    display: flex;
    margin: auto;
}
.menuIcon {
    position: absolute;
    left: 2vmin;
}

.nav-right {
    float: right;
  }

.nav-link {
float: right;
color: white;
background-color: inherit;
text-align: center;
text-decoration: none;
font-size: 15px;
font-family: "Poppins";
    
/* these last two make the logout button in
the nav bar look like another link */
border:none;
cursor: pointer;
outline:0;
}