.addressDiv {
    margin-top: 12px;
}

#contactInfoForm {
    height: 411px;
}

input[type="text"]:disabled {
    color: #CBCCD4
  }

  @media (min-width: 800.1px) {
    #cityInput {
        width: 200px;
        margin-right: 20px;
    }
    #stateSelect {
        margin-right: 20px;
        width: 85px;
        margin-top: 1px;
    }
    #zipCodeInput {
        width: 165px;
    }
}

@media (max-width: 800px) {
    #cityInput {
        width: 100%;
        margin-right: 20px;
    }
    #stateSelect {
        margin-right: 20px;
        width: 85px;
        margin-top: 1px;
    }
    #zipCodeInput {
        width: 225px;
    }
}