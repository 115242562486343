.confirmationButtonStyle {
    font-Weight: bold;
    display: inline-block;
    align-items: center;
    margin: 0;
    border-radius: 25px;
    height: 50px;
    width: 168px;
    font-size: 16px;
    line-height: 16px;;
    text-align: center;
}

.rightConfirmationColumn {
    float: right;
}

.leftConfirmationColumn {
    width: 25%;
    float: left;
    margin-left: 30%;
}

.confirmationColumn {
    margin: 0;
}

.confirmationButtons {
    margin-top: 8%;
    margin-bottom: 10vh;
}

.confirmationInfoLabel {
    text-align: left;
    margin: 0;
    color: #000035;
    font-size: 18px;
}

.confirmationText {
    margin: 0;
    font-size: 14px;
    margin-bottom: 8px;
}