@media (min-width: 800.1px) {
    #confirmDatesRadioGroup {
        background-color: white;
        display: block;
        width: 150px;
        margin: auto;
    }
}

@media (max-width: 800px) {
    #confirmDatesRadioGroup {
        background-color: white;
        display: block;
        margin: auto;
        width: 40%;
    }
}